:root { 
--blue_gray_200_99:#abbed199; 
--white_A700_b2:#ffffffb2; 
--blue_gray_200_4c:#abbed14c; 
--teal_900:#055b49; 
--gray_900:#1e1e1e; 
--yellow_50:#fff6e7; 
--black_900_19:#00000019; 
--white_A700:#ffffff; 
--teal_900_01:#034134; 
}